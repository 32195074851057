import React from "react"
import { graphql, Link } from "gatsby"
import Layout from '../../components/layout';
import Seo from "../../components/seo";
import HeaderPage from '../../utils/HeaderPage';
import LogoImage from '../../images/thumnail_blog_default.png';

const Patents = (props) => {
  let catName = props.pageContext.catName || 'Patents';
  if (!catName.endsWith('Patents') && !catName.startsWith('Patent')) {
    catName += ' Patents'
  }
  const PATENTS = props.data.PATENTS.nodes;
  const canonicalUrl = `/blogs/patents`;
  const seo = {};

  return (
    <Layout>
      <Seo title={ catName } canonical={ canonicalUrl } seo={seo} />
      <HeaderPage headerTxt={ catName }></HeaderPage>
      <section className='mainSpacing2'>
        <div className="container">
        <div className="row">
            {
            PATENTS.map((node, index) => (
              <div className="col-md-4 col-sm12">
                <div class="card mb-5 border-0">
                  <div class="row g-0">
                    <div class="col-md-4">
                      {
                        node.featuredImage?.node.mediaItemUrl ? <img width="138" style={{"height": 92 + 'px'}} src={node.featuredImage?.node.mediaItemUrl} class="img-fluid rounded-start" alt={node.title}/>
                        : <img src={LogoImage} width="138" style={{"height": 92 + 'px'}}/>
                      }
                      
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                        <p class="card-text"><a className="text-decoration-none" href={"/blog/" + node.slug} aria-current="true">
                              {node.title}
                          </a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
            }
          </div>
          <div className="mb-5"></div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($catName: String) {
    PATENTS: allWpPost(
    filter: {categories: {nodes: {elemMatch: {name: {eq: $catName}}}}}
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        id
        uri
        title
        seo {
            metaDesc
            focuskw
        }
        slug
        date(fromNow: true)
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Patents;
